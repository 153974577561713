<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>招聘会历史</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch">
                <div class="searchleft">
                    <el-form :inline="true" :model="searchform" class="demo-form-inline">
                    <el-form-item label="标题">
                        <el-input v-model="searchform.jobName" size="small" placeholder="标题"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="searchform.status" size="small" placeholder="全部">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="预约中" value="1"></el-option>
                        <el-option label="已开始" value="2"></el-option>
                        <el-option label="已结束" value="3"></el-option>
                        <!-- <el-option label="已结束" value="3"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发布状态">
                        <el-select v-model="searchform.postStatus" size="small" placeholder="全部">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未发布" value="1"></el-option>
                        <el-option label="已发布" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="onSubmit"  class="mybotton">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" size="small" @click="gotoBack" class="mybotton4">返回招聘会</el-button>
                    </el-form-item>
                    </el-form>
                </div>
                <div class="searchright"></div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="id"
                    align="center"
                    label="ID"
                    width="40">
                    </el-table-column>
                    <el-table-column
                    prop="jobName"
                    label="标题"
                    width="260">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    align="center"
                    label="图片">
                        <template slot-scope="scope">
                            <show-img  :url="scope.row.url" width="300" wid="40px"></show-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    v-if="searchform.status!=''"
                    label="状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status==3"  size="mini">已结束</el-tag>
                            <el-tag v-if="scope.row.status==2" type="danger" size="mini">已开始</el-tag>
                            <el-tag v-if="scope.row.status==1" type="warning" size="mini">预约中</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="status"
                    width="180"
                    label="发布状态">
                        <template slot-scope="scope">
                            <el-switch
                            v-model="scope.row.postStatus"
                            :active-value="2"
                            :inactive-value="1"
                            active-color="#404040"
                            inactive-color="#d9d9d9"
                            active-text=""
                            inactive-text=""
                            @change="changestatu($event,scope.row)"
                            >
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    label="负责人">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    align="center"
                    label="展位方案">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.boothId!=0" type="primary" size="mini" class="tagout">已选</el-tag>
                            <el-tag v-else type="info" size="mini" class="tagout">未选</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="contactsPhone"
                    width="130"
                    label="电话">
                    </el-table-column>
                    <el-table-column
                    prop="companiesNum"
                    align="center"
                    label="预约企业数">
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    width="220"
                    label="时间">
                        <template slot-scope="scope">
                            开始：{{scope.row.holdStartTime}} <br />
                            结束：{{scope.row.holdEndTime}}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="250"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" class="mybotton" @click="deletejobfair(scope.row)">恢复</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :pageSize="pageSize" :current="pageNo" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>

        <pop-up :title="'方案设置'" width="30%" :isshow="setprogramme" :haveconfirm="false" @close="getclose" @confirm="">
            <div slot="box">
                <el-table
                :data="programmelist"
                :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="partitionTitle"
                    width="280"
                    label="方案名">
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    label="方案图">
                        <template slot-scope="scope">
                            <show-img  :url="scope.row.floorPlan" width="300" wid="30px"></show-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    width="75"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="setproid!=scope.row.id" type="primary" size="mini" class="mybotton2" @click="setpro(scope.row)">选择</el-button>
                            <el-tag v-else type="success" size="mini" effect="dark" >已选择</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </pop-up>

    </div>
</template>
<script>
import apiurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            setid:"",//要设置方案的招聘会id
            setproid:"",//正在编辑的招聘会原始方案的id
            setproname:"",//正在编辑的招聘会原始方案的name
            setprogramme:false,//显示方案设置框
            searchform:{
                jobName:"",
                status:"",
                postStatus:"",
            },
            list:[],
            programmelist:[],
            total:0,
            pageSize:10,
            pageNo:1,
            loading:false,
        }
    },
    created(){
        this.getList()
        this.getPro()
    },
    methods:{
        gotoBack(){
            this.$router.push("/jobfair/index")
        },
        gotoShow(row){
            this.$router.push({path:"/jobfair/show",query:{id:row.id}})
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        deletejobfair(row){
            this.$confirm('真的确定要恢复吗？是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.get(apiurl.delJob,{id:row.id,flag:false}).then(res=>{
                    console.log(res)
                    if(res.success){
                        this.$message({
                            type: 'success',
                            message: '恢复成功!'
                        });
                        this.getList()
                    }
                })
                
            }).catch(() => {        
            });
        },
        getList(){
            this.loading=true
            _api.post(apiurl.jobFairdelList,{jobName:this.searchform.jobName,status:this.searchform.status,postStatus:this.searchform.postStatus,pageNo:this.pageNo,pageSize:this.pageSize}).then(res=>{
                this.loading=false
                console.log(res)
                if(res.success){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        gotoEdit(row){
            this.$router.push({path:"/jobfair/edit",query:{id:row.id}})
        },
        changestatu(data,row){//切换发布状态
            _api.get(apiurl.postStatus,{id:row.id,postStatus:data}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("操作成功！");
                    this.getList()
                }
            })
        },
        setpro(row){
            _api.get(apiurl.selectBooth,{id:this.setid,boothId:row.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("设置成功！")
                    this.setproid=row.id
                    var findarr2=this.list.find((n)=>(n.id==this.setid))
                    this.$set(findarr2,"boothId",row.id)
                    this.$set(findarr2,"partitionTitle",row.partitionTitle)
                }
            })
        },
        getPro(){//获取方案的列表没有分页
            _api.get(apiurl.boothList,{}).then(res=>{
                console.log(res)
                this.programmelist=res.data
            })
        },
        openset(row){//打开方案设置框
            this.setprogramme=true
            this.setid=row.id
            this.setproid=row.boothId
            this.setproname=row.partitionTitle
        },
        getclose(){//关闭框
            this.setprogramme=false
        },
        getconfirm(){//确认设置
            this.setprogramme=false
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>
<style>
 .el-loading-spinner .path{    stroke: #ff704f;}
 .el-switch__label{color: #bdbdbd;}
 .el-switch__label.el-switch__label--right.is-active{color: #3968d0;}
 .el-switch__label.el-switch__label--left.is-active{color: #d9d9d9;}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch{ overflow: hidden;}

</style>
